 * {
     font-family: "Montserrat", sans-serif !important;
 }

 iframe {
     width: 0 !important;
     height: 0 !important;
     z-index: -99 !important;
 }

 .error {
     margin-top: 3px;
     font-size: 0.813rem;
     color: rgb(160, 0, 0);
 }

 .cardData {
     min-height: 190px !important;
 }

 .cardData-item {
     max-height: 561px;
 }

 .table-hover th {
     padding: 0.92rem 1rem !important;

 }

 .table-hover td {
     padding: 0.42rem 1rem !important;
 }

 .container-timeline {
     width: 80%;
     padding: 10px 0;
     position: relative;
     min-height: 100px;
     max-height: 400px;
     overflow-y: auto;

 }

 .container-timeline::-webkit-scrollbar-track {
     background-color: #fff;
     border: 1px solid #7367f0;
 }

 .container-timeline::-webkit-scrollbar {
     width: 8px;
     background-color: #7367f0;
 }

 .container-timeline::-webkit-scrollbar-thumb {
     border-radius: 10px;
     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
     background-color: #7367f0;
 }

 .table-responsive::-webkit-scrollbar-track {
     background-color: #fff;
     border: 1px solid #7367f0;
 }

 .table-responsive::-webkit-scrollbar {
     height: 7px !important;
     background-color: #7367f0;
 }

 .table-responsive::-webkit-scrollbar-thumb {
     border-radius: 5px;
     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
     background-color: #7367f0;
 }

 .container-timeline .timeline-block:before {
     content: '';
     position: absolute;
     top: 16px;
     width: 2px;
     height: 100%;
     margin-left: 7px;
     border-left: 2px dotted #CCD1D9;
     z-index: 1;
 }

 .timeline-block {
     width: -webkit-calc(100% - 10px);
     width: -moz-calc(100% - 10px);
     width: calc(100% - 10px);
     display: -webkit-box;
     display: -webkit-flex;
     display: -moz-box;
     display: flex;
     -webkit-box-pack: justify;
     -webkit-justify-content: space-between;
     -moz-box-pack: justify;
     justify-content: space-between;
     clear: both;
 }

 .timeline-block-right {
     float: right;
     overflow: hidden;
     position: relative;
 }

 .timeline-block-left {
     float: left;
     direction: rtl
 }

 .marker {
     width: 16px;
     height: 16px;
     border-radius: 50%;
     border: 1px solid #3730a3;
     background: #f5f6fd;
     z-index: 9999
 }

 .timeline-content {
     width: 95%;
     padding: 0 15px 0 0;
     color: #666
 }

 .timeline-content h3 {
     margin-top: 5px;
     margin-bottom: 5px;
     font-size: 1.563rem;
     font-weight: 500
 }

 .timeline-content span {
     font-size: 0.938rem;
     color: #a4a4a4;
 }

 .timeline-content p {
     font-size: 0.875rem;
     line-height: 1.5em;
     word-spacing: 1px;
     color: #888;
 }

 /* timeline end*/
 .btn-close {
     margin-top: -40px !important;
 }

 .get-in-touch {
     background-color: #fff !important;
     color: #3730a3;
     width: 150px;
     padding: 14px;
     border: 1px solid #3730a3;
     border-radius: 10px;
     font-size: 1.063rem;
     font-weight: 600;

 }

 .defaultRecord {
     display: flex;
     flex-direction: row;
     justify-content: space-around;
     align-items: center;
     padding: 10px 0;
 }


 .refreshAll:active {
     background-color: #fff !important;
     color: #3730a3;
 }

 .pagination ul {
     margin-block-start: 0;
     margin-block-end: 0;
     margin-bottom: 0;
 }

 .pagination {
     display: flex;
     justify-content: space-between;
 }

 .pagination-page-limit {
     justify-content: center;
     align-items: center;

 }

 .pagination-page-limit>* {
     padding: 0 1rem;
     justify-content: center;
 }

 .pagination-page-limit p {
     margin-top: 1rem;
 }

 .pagination-select {
     width: 5rem !important;
 }

 .light-color {
     color: #000;
 }

 .dark-color {
     color: #000;
 }

 .set-img {
     right: 20px;
     bottom: 0px;
     position: absolute;
 }



 .change-color {
     background-color: #f5e6e6;
 }

 .shipment-table {
     min-width: 965px;
 }

 .navigation-main {
     background: transparent !important;
 }



 .up-arrow svg {
     width: 20px;
     height: 20px;
     color: #000;
 }

 .admin-table thead tr th {
     background: transparent !important;
 }

 .admin-table {
     min-width: 1200px;
 }

 .shipment-table thead tr th {
     background: transparent !important;
     padding: 1rem 1rem !important;
 }

 .shipment-table tbody tr td {
     background: transparent !important;
     padding: 0.52rem 1rem !important;
 }


 .card-body-data ul li a {
     min-width: 184px !important;
 }

 .custom-tabs {
     display: flex;
     justify-content: space-between;
     flex-wrap: wrap;
 }

 .custom-tabs-2 {
     display: flex;
     justify-content: space-between;
     flex-wrap: wrap;
     border-bottom: 1px solid #ccc;
 }

 .custom-tabs .nav-link.active {
     border-bottom: 3px solid #7367f0;
 }

 .custom-tabs-2 .nav-link.active {
     border-bottom: 3px solid #7367f0;
 }

 .url-item {
     background-color: #00dc73 !important;
     font-size: 85%;
     font-weight: 600;
     color: #fff;
     border-radius: 0.358rem;
 }

 .custom-tabs .nav-link {
     padding: 10px 2px !important;
     min-width: 100px;
     text-align: center;
     font-weight: 500;
 }

 .custom-tabs-2 .nav-link {
     padding: 10px 2px !important;
     min-width: 110px;
     text-align: center;
     font-weight: 500;
 }

 .tab-modal-custom .nav-link {
     padding: 12px 2px !important;
     min-width: 115px;
     text-align: center;
 }

 .access {
     padding: 5px;
 }

 .access2 {
     margin-bottom: 5px;
 }

 

 .store-tag {
     width: 125px;
 }

 .statistics-body1 {
     padding: 1rem 1.4rem !important;
 }

 .shipment-table tbody tr td:first-child {
     width: 0 !important;
 }

 .shipment-table thead tr th:first-child {
     width: 0 !important;
 }

 .data-select {
     padding: .786rem 1rem !important;
 }

 .donught-chart .apexcharts-datalabels-group text {
     font-size: 1rem !important;
 }

 /* vertical navbar item's link box-shadow removed  */
 .vertical-layout.vertical-menu-modern .main-menu .navigation li a {
     box-shadow: none !important;
 }

 .sort-icons {
     visibility: hidden;
     margin-top: -2px;
     padding-left: 2px;
     color: lightgray;
     transition: transform 0.2s ease;
 }

 .list-item-heading:hover .sort-icons {
     visibility: visible;
 }

 .sort-icon-rotate {
     transform: rotateZ(180deg);
     transition: transform 0.2s ease;
 }

 .sort-icon-onclick {
     color: grey;
 }

 .dropdown-menu {
     min-width: 100% !important;
     top: 0 !important;
     color: gray !important;
 }

 .daybefore_span {
     font-size: 0.857rem;
     text-align: center;
     color: grey !important;
 }

 .dropdown-menu h6 {
     text-align: center !important;
     color: grey !important;
 }

 .delivery-status {
     margin-top: 0.3rem;
 }

 .date-picker-component {
     font-weight: 600 !important;
     width: 220px !important;
 }

 .shipment-date-range-type {
     width: 150px !important;
     margin-right: 1rem !important;
 }

 .invoice-list-dataTable>div {
     overflow-y: scroll !important;
     height: 390px !important;
 }

 ::-webkit-scrollbar {
     width: 8px;
     height: 8px;
 }

 ::-webkit-scrollbar-track-piece {
     background: #888
 }

 ::-webkit-scrollbar-thumb {
     background: #7367f0
 }

 .time-date-order-status {
     text-align: center;
 }

 .apexcharts-legend {
     display: flex !important;
     align-items: baseline !important;
     justify-content: flex-start !important;
 }

 .apexcharts-legend>div {
     font-size: 12px !important;
     width: calc(33.33% - 20px) !important;
 }

 .apexcharts-legend-marker {
     width: 12px !important;
     height: 12px !important;
     border-radius: 6px !important;
 }

 @media(max-width: 1299px) {
     .custom-tabs {
         justify-content: center;
         border: none;
     }



     .custom-tabs .nav-link {
         padding: 5px 10px !important;
         margin: 1px;
     }


 }

 @media(max-width: 1024px) {
     .custom-tabs-2 {
         justify-content: center;
         border: none;
     }

     .custom-tabs {
         display: flex;
         justify-content: space-between;
         flex-wrap: wrap;
         border-bottom: 1px solid #ccc;
     }

     .custom-tabs-2 .nav-link {
         padding: 5px 10px !important;
         background: #f9f9f9;
         margin: 1px;
     }

     .main-menu.menu-light .navigation>li ul .active {
         box-shadow: none !important;
     }

     .shipment-select-range-wrapper-1024 {
         padding-top: 1rem !important;
     }
 }

 .event-time {
     margin-top: -5px !important;
 }

 .event-time-label {
     color: #7367f0 !important;
     font-weight: 800 !important;
 }

 .event-time-wrapper {
     width: 50% !important;
 }

 .transit-label {
     text-align: center !important;
     margin-top: -10px !important;
     font-size: 0.625rem !important;
 }

 .transit-time {
     text-align: center !important;
     margin-top: -15px !important;
     font-size: 1.285rem !important;
     color: #7367f0 !important;
 }

 .delivery-label {
     text-align: center !important;
     margin-top: -15px !important;
     color: #7367f0 !important;
     font-weight: 500 !important;
 }

 .shipment-status-label {
     color: "#7367f0 " !important;
     font-size: "1.285rem" !important;
 }

 .time-data-status-container {
     border-radius: 8px !important;
 }

 .update-user-label {
     font-size: 1.563rem !important;
 }

 .email-warning {
     color: "red" !important;
     font-size: "0.625rem" !important;
 }

 .analytics-card-label {
     font-size: 1.063rem !important;
     font-weight: 800 !important;
     margin-bottom: 4px !important;
 }

 .analytics-card-value {
     font-size: 0.75rem !important;
     margin-bottom: 4px !important;
 }

 .analytics-card-comparison {
     font-size: 1rem !important;
     font-weight: 500 !important;
 }

 .analytics-card-comparison-percentage {
     font-size: 0.875rem !important;
     color: #ea5455 !important;
 }

 .analytics-card-heading {
     padding: 10px !important;
     border-radius: 6px !important;
 }





 .timedata-wrapper {
     margin: 10px;
 }

 .apexcharts-series.apexcharts-pie-series>path {
     stroke-width: 0 !important;
 }

 .mobile-number {
     width: max-content;
 }

 .no-data-wrapper {
     height: 329px;
     display: flex;
     justify-content: center;
     align-items: center;

 }

 .no-data-upper {
     width: 250px;
     height: 250px;
     border-radius: 50%;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .no-data-upper-dark {

     background-color: #343d55;
 }

 .no-data-upper-light {
     background-color: #f3f2f7;

 }

 .no-data-inner {
     width: 155px;
     height: 155px;
     border-radius: 50%;
     display: flex;
     justify-content: center;
     align-items: center;
     color: #676d7d;
 }

 .no-data-inner>span {
     font-weight: 600 !important;

 }

 .no-data-inner-light {
     background-color: white;
 }

 .no-data-inner-dark {
     background-color: #283046;
 }


 .card-submit-btn button {
     width: calc(100% - 2rem) !important;
 }

 @media (min-width:769px) and (max-width:928px) {
     .shipment-select-range-wrapper {
         padding-top: 1rem !important;
     }
 }

 @media (min-width:1025px) and (max-width:1149px) {
     .shipment-select-range-wrapper-1024 {
         padding-top: 1rem !important;
     }
 }

 @media (min-width:1200px) and (max-width:1410px) {
     .shipment-select-range-wrapper-1024 {
         padding-top: 1rem !important;
     }

     .apexcharts-datalabels>text {
         font-size: 10px !important;
     }
 }

 @media (min-width:1200px) and (max-width:1450px) {
     .apexcharts-legend>div {
         width: calc(50% - 20px) !important;
     }

     .apexcharts-datalabels>text {
         font-size: 10px !important;
     }
 }

 @media(max-width:768px) {
     .shipment-select-range-wrapper-1024 {
         padding-top: 0rem !important;
     }
 }

 @media(max-width: 575px) {
     .custom-tabs .nav-link {
         margin: 2px;
         width: calc(50% - 4px);
     }

     .custom-tabs-2 .nav-link {
         margin: 2px;
         width: calc(50% - 4px);
     }

     .main-menu.menu-light .navigation>li ul .active {
         box-shadow: none !important;
     }
 }

 @media(max-width: 425px) {
     .custom-tabs .nav-link {
         width: 100%;
     }

     .custom-tabs-2 .nav-link {
         width: 100%;
     }

     .shipment-select-range-wrapper {
         gap: 1rem !important;
         flex-wrap: wrap !important;
         padding-top: 0 !important;
     }

     .input-group-merge-wrapper {
         height: 40px;
     }

     .input-group-merge-wrapper>div {
         padding-left: 0 !important;

     }
 }

 @media(max-width: 527px) {
     .status-select {
         padding-bottom: 10px !important;
     }
 }

 .shipment-table td {
     padding: 0.42rem 1rem !important;
 }

 .shipment-table th {
     padding: 0.92rem 1rem !important;
 }

 .user-list-link span {
     color: #343d55;
 }

 .status-head-color {
     color: #7367f0 !important;
 }

 .table-row-item {
     font-weight: 500 !important;
     line-height: 1.2 !important;
 }

 .stores-wrapper {
     height: max-content !important;
 }

 .form-label-dark {
     color: #d0d2d6 !important;
 }

 .form-label-light {
     color: #5e5873 !important;
 }

 .shipment-table thead tr th {
     background: transparent !important;
     padding: 0.92rem 1rem !important;
 }

 .shipment-table tbody tr td {
     padding: 0.72rem 1rem !important;
 }

 .shipment-table.shipment-table-dark thead tr {
     background-color: #343d55 !important;
 }

 .shipment-table.shipment-table-light thead tr {
     background-color: #f3f2f7 !important;
 }

 .shipment-table.shipment-table-dark tbody tr:hover {
     background-color: #343d55 !important;
 }

 .shipment-table.shipment-table-light tbody tr:hover {
     background-color: #f3f2f7 !important;
 }

 .admin-table thead tr th {

     padding: 0.92rem 1.5rem !important;
 }

 .admin-table.admin-table-dark tr th {

     background-color: #343d55 !important;
 }

 .admin-table.admin-table-light tr th {

     background-color: #f3f2f7 !important;
 }

 .admin-table tbody tr td {
     padding: 0.72rem 1.5rem !important;
 }

 .admin-table.admin-table-dark tbody tr:hover {
     background-color: #343d55 !important;
 }

 .admin-table.admin-table-light tbody tr:hover {
     background-color: #f3f2f7 !important;
 }

 .font-small-table {
     font-size: 13px !important;
     font-weight: 400 !important;
 }

 .react-dataTable-dark .rdt_TableBody .rdt_TableRow:hover {
     background-color: #343d55 !important;
 }

 .react-dataTable-light .rdt_TableBody .rdt_TableRow:hover {
     background-color: #f3f2f7 !important;
 }

 .status-badge {
     display: flex !important;
     align-items: center !important;
     width: max-content !important;
 }

 .permissions-elements {
     margin-right: 1rem;

 }
 
 .permissions-elements-1 svg {
     stroke: #5cb85c !important;
 }

 .permissions-elements-2 svg {
     stroke: #f0ad4e !important;
 }

 .permissions-elements-3 svg {
     stroke: #ea5455 !important;
 }

 .brand-logo img {
     width: 2.8rem;
 }

 .pagination-light .break {
     background-color: #f3f2f7;
 }

 .dark-layout .flatpickr-weekdays,
 .dark-layout .flatpickr-weekday,
 .dark-layout .flatpickr-month,
 .dark-layout .flatpickr-monthDropdown-months {
     background: #343d55 !important;
 }

 .flatpickr-weekdays {
     margin-top: 0 !important;
 }

 .invoice-date-title {
     width: max-content !important;
 } 

 .react-dataTable-light .rdt_Table div {
     color: #6e6b7b !important;
 }


 .invoice-total-wrapper>.invoice-total-title {
     gap: 1 !important;
     justify-content: start !important;
 }

 .shopify-store-url {
    padding: 0.1rem 0.5rem ; 
 }

 .shipment-table tr td[colspan="12"] {
     display: none !important;
 }

 .table-nav-button-dark{
    border-color: #404656 !important;
 }
 .table-nav-button-light{
    border-color: #d8d6de !important;
 }
.arrowContainerClass button{
background-color: #e8e8e8;
z-index: 1;
}
.react-multi-carousel-dot-list li button {
border: none;
}